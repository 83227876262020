.lottery-menu {
    margin: 0.5rem -0.2rem 0.5rem -0.2rem;
}
.lottery-menu > div {
    display: flex;
}
.lottery-menu-top > .lottery-item {
    position: relative;
    width: 38.2%;
    color: #fff;
    font-size: 0.4rem;
}
.lottery-menu-top > .lottery-item span {
    position: absolute;
    width: 100%;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
}
.lottery-menu-top > .lottery-item:nth-child(2) {
    width: 30.3%;
}
.lottery-menu-top > .lottery-item:nth-child(3) {
    width: 31.5%;
}
.lottery-menu-mid {
    background-image: url(../../img/boxbg.png), linear-gradient(299deg, rgba(255, 229, 135, .2), rgba(255, 229, 135, 0) 108.52%), linear-gradient(179deg, #ff9614 -16.37%, #db6813 31.58%, #3b1d04 80.54%);
    background-size: 100%;
    border-radius: .3rem;
    box-shadow: 0 .02rem .06rem rgba(0, 0, 0, .2), inset 0 .01rem .02rem rgba(255, 250, 241, .8), inset 0 -.01rem .03rem #fdbfbc;
    margin: 0 .16rem;
}
.lottery-menu-mid > .lottery-item {
    flex: 1;
    position: relative;
    color: #fff;
    font-size: 0.35rem;
}
.lottery-menu-mid > .lottery-item:after {
    content: "";
    border-right: 0.01rem solid hsla(0, 0%, 100%, 0.1);
    position: absolute;
    top: 20%;
    height: 60%;
    right: 0;
}
.lottery-menu-mid > .lottery-item span {
    position: absolute;
    width: 100%;
    bottom: 0.15rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
}
.lottery-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    background: #fff;
    border-radius: 0.3rem;
    overflow: hidden;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
}
.lottery-list .lottery-item {
    width: calc(100% / 3);
    border-left: 0.03rem solid #ebebeb;
    padding: 10px 0;
}
.lottery-list .lottery-item:first-child {
    border: 0;
}
.lottery-list .lottery-item img {
    filter: drop-shadow(0 0 0.5rem rgba(255, 138, 211, 0.5));
    border: 0.05rem solid #fff;
    border-radius: 100%;
    max-width: 1.3rem;
}
.lottery-list .lottery-item span {
    color: #333;
    font-size: 0.3rem;
    width: 100%;
    display: block;
}
